import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Header from './components/Header';
import HomePage from './pages/HomePage';
import AboutUsPage from './pages/AboutUsPage';
import Footer from './components/Footer';
import Login from './pages/Login';
import JoinUs from './pages/JoinUs';
import AlternativeInvestment from './pages/AlternativeInvestment';
import FamilyPartners from './pages/FamilyPartners';
import News from './pages/News';
import NotFoundPage from './pages/NotFoundPage'; // Import the NotFoundPage component

function App() {
  const logoUrl = 'img/heroics-logo-rgb.png';
  const categories = [
    {
      name: 'Home',
      path: '/',
      component: <HomePage />,
    },
    {
      name: 'Alternative Investments',
      path: '/alternative-investment',
      component: <AlternativeInvestment />,
      catchPhrase: 'Unlock Unique Opportunities in the World of Alternative Investments',
    },
    {
      name: 'Family Partners',
      path: '/family-partners',
      component: <FamilyPartners />,
      catchPhrase: 'Build Generational Wealth Together with Family Partners',
    },
    {
      name: 'About us',
      path: '/about-us',
      component: <AboutUsPage />,
      catchPhrase: 'Meet the Minds Behind Your Success: Our Dedicated Team',
    },
    {
      name: 'News',
      path: '/news',
      component: <News />,
      catchPhrase: 'Stay Ahead with the Latest Insights and Market News',
    },
    {
      name: 'Log in',
      path: '/login',
      component: <Login />,
      catchPhrase: 'Log In to Secure Your Financial Future',
    },
    {
      name: 'Join us',
      path: '/join-heroics',
      component: <JoinUs />,
      catchPhrase: 'Join Us and Become Part of an Elite Investment Circle',
    },
    ]

  return (
    <>
      <BrowserRouter>
        <div
          className={`w-full text-justify pb-32 font-ppmori max-w-screen overflow-x-hidden z-10`}
        >
          <div>
            <Header logoUrl={logoUrl} categories={categories} />
          </div>
          <div
            className={`text-white w-full flex justify-center items-center z-30`}
          >
            <Routes>
              {categories.map((category, index) => (
                <Route
                  key={index}
                  path={category.path}
                  element={category.component}
                />
              ))}
              {/* Add a catch-all route for 404 */}
              <Route path='*' element={<NotFoundPage />} />
            </Routes>
          </div>
        </div>

        <div className='z-0 font-ppmori'>
          <Footer categories={categories} />
        </div>
      </BrowserRouter>
    </>
  );
}

export default App;
