import React, { useState } from 'react';
// import key_members from '../data/team_info';
import '../css/aboutus.css';

function TeamSkill({ title, description }) {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleCardClick = () => {
    setIsFlipped(!isFlipped);
    console.log(isFlipped);
  };

  return (
    <div
      className={`hover:scale-105 card m-5 w-[400px] h-[200px] flex justify-center rounded-lg transition-transform duration-200 ${
        isFlipped ? 'flipped' : ''
      }`}
      onClick={handleCardClick}
    >
      <div className='card-inner w-full text-white bg-deepblue rounded-lg '>
        <div className='card-front flex justify-center text-center'>
          <div className='text-center text-xl flex justify-center items-center'>
            <p>{title}</p>
          </div>
        </div>
        <div className='card-back px-8 bg-blue-500 flex flex-col justify-center rounded-lg items-center'>
          <div className='text-center flex justify-center w-full text-xl py-2'>
            <p>{title}</p>
          </div>
          <div className='text-center flex justify-center w-full'>
            <p>{description}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

function TeamSkills() {
  const team_skills = [
    {
      title: 'Multi Asset Trading',
      description:
        'Proven track record trading and managing vanilla & complex OTC / listed cross assets derivatives portfolio.',
    },
    {
      title: 'Financial & Quant Engineering',
      description:
        'Strong competences for creating option strategies to optimize one right trade idea.',
    },
    {
      title: 'Fundamental & Macro Research',
      description:
        'Top understanding of all macro topics, capabilities to analyze data and extract the relevant information to allow the team to define an investment strategy.',
    },
    {
      title: 'Advanced Risk Management',
      description:
        'Proficiency in mathematical concepts related to the management of a portfolio of simple and complex derivative products.',
    },
    {
      title: 'Execution',
      description:
        'The team complies with best execution policies for trading all types of assets & derivatives, and uses best digital market venues to trade, reducing execution risks.',
    },
    {
      title: 'IT Research & Programming',
      description:
        'Dedicated staff for IT Quant / research with high skills in programming.',
    },
  ];
  return (
    <section className=' p-16 text-center w-full text-black bg-white '>
      <h2 className='text-3xl font-bold m-10'>Team Major Skills</h2>
      <div className='text-justify mx-auto p-10 flex flex-wrap justify-center '>
        {team_skills.map((skill, index) => (
          <TeamSkill
            key={index}
            title={skill.title}
            description={skill.description}
          />
        ))}
      </div>
    </section>
  );
}
//import './MemberCard2.css'; // Import your CSS file for animations

// function MemberCard({ member, index }) {
//   const [isClicked, setIsClicked] = useState(false);

//   const handleClick = () => {
//     setIsClicked(!isClicked); // Toggle the clicked state
//   };

//   return (
//     <div className='m-8 relative group cursor-pointer' onClick={handleClick}>
//       <div className='relative'>
//         <img
//           className='w-[300px] h-[500px] object-cover border-b-[32px] border-deepblue'
//           src={member.img}
//           alt={`Founder ${index + 1}`}
//         />
//         {/* Overlay with dynamic opacity */}
//         <div className='absolute inset-0 bg-deepblue bg-opacity-0 transition-colors duration-500 hover:bg-deepblue hover:bg-opacity-60'></div>
//       </div>
//       <div className='absolute bottom-4 left-0 right-0 text-white text-center py-4 z-10'>
//         <h4 className='text-md font-semibold mb-2'>{member.name}</h4>
//         <p className='pt-0 pb-2'>{member.team}</p>
//         <p className='text-lg font-bold text-blue-600 mb-2'>
//           {member.position}
//         </p>
//         {/* Toggleable detailed view */}
//         {isClicked && (
//           <div className='bg-white text-black p-4 absolute bottom-4 left-0 right-0 shadow-lg'>
//             <h4 className='text-md font-semibold mb-2 pt-2'>{member.name}</h4>
//             <p className='pt-0 pb-2'>{member.team}</p>
//             <p className='text-lg font-bold text-blue-600 mb-2'>
//               {member.position}
//             </p>
//             <p className='text-sm font-bold'>{member.timeofexp}</p>
//             <p className='text-sm'>{member.experience}</p>
//             <p className='text-sm'>{member.previous_position}</p>
//             {member.specialization && (
//               <p className='text-sm p-1'>{`Specialization: ${member.specialization}`}</p>
//             )}
//             <ul className='text-sm p-1 pt-3'>
//               {member.education.map((edu, idx) => (
//                 <li key={idx}>{edu}</li>
//               ))}
//             </ul>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// }

function AboutUsPage() {
  return (
    <div className='items-center w-full p-0'>
      <img
        className='fixed top-0 left-0 w-full -z-50'
        src='img/ny.png'
        alt='clocher'
      />
      <img
        className='w-64 fixed mt-32 right-1/3 -z-50'
        src='img/heroics-logo-rgb blanc.png'
        alt='logo'
      ></img>
      <div className=' w-full mt-[500px] '>
        {/* Team Major Skills */}
        <TeamSkills />

        {/* Key Members */}
        {/* <section className='mb-8 text-center w-full bg-mist text-black'>
          <h2 className='text-3xl font-bold m-10'>The Team</h2>
          <h3 className='text-2xl font-semibold mb-4'>Key Members</h3>

          <div className='flex flex-wrap items-center justify-center p-0 w-full'>
            {key_members.map((member, index) => (
              <MemberCard key={index} member={member} />
            ))}
          </div>
        </section> */}
      </div>
    </div>
  );
}

export default AboutUsPage;
