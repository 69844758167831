import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import React, { useState, useEffect } from 'react';

const getRandomDuration = (min, max) => {
  return Math.random() * (max - min) + min;
};

function Card({ title, body, imgPath, link }) {
  const [ref, inView] = useInView({ threshold: 0.2 });

  const variants = {
    hidden: { opacity: 0, y: 200 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <motion.div
      className='w-[300px] m-2'
      ref={ref}
      initial='hidden'
      animate={inView ? 'visible' : 'hidden'}
      variants={variants}
      transition={{
        duration: getRandomDuration(0.8, 1.2),
        ease: 'easeOut',
      }}
    >
      <a href={link}>
        <div class='py-4 rounded-lg transition-colors duration-500 hover:bg-waylessdeepblue shadow-3xl bg-lessdeepblue'>
          <div className='w-full flex justify-center rounded-full '>
            <img
              className='h-[100px] w-[100px] rounded-full border border-white border-2 bg-white p-2'
              src={imgPath}
              alt={title}
            />
          </div>
          <div className='flex flex-col justify-between h-[200px]'>
            <div className='p-4 flex-none'>
              <h2 className='text-lg font-semibold mb-2 text-white justify-center text-center'>
                {title}
              </h2>
            </div>
            <div className='flex-auto p-2 flex justify-center items-end text-center'>
              <div className={`border-t`}>
                <p className='text-white mt-4'>{body}</p>
              </div>
            </div>
          </div>
        </div>
      </a>
    </motion.div>
  );
}

export default function InvestmentSolutions() {
  const [ref, inView] = useInView({ threshold: 0.5 });
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <div className='bg-deepblue relative z-0 '>
        <div ref={ref}>
          <motion.div
            initial={{ y: -200, opacity: 1 }}
            animate={inView ? { y: 0, opacity: 1 } : {}}
            transition={{ duration: 1, ease: 'easeInOut' }}
            className='w-full'
          >
            <div
              className={`font-bold text-7xl justify-center flex text-center  text-white p-32 ${
                screenWidth < 1054 ? 'py-4' : 'py-32'
              }`}
            >
              <h1>Investment Solutions</h1>
            </div>
          </motion.div>
        </div>

        <div className='CARDS p-10 flex justify-center flex-wrap'>
          <Card
            title='Alternative Investment Funds'
            body='Leveraging liquid and innovative financial instruments to diversify and enhance portfolio performance.'
            imgPath='img/alternative.png'
            link='/alternative-investment'
          />
          <Card
            title='Discretionary Portfolio Management'
            body='Providing bespoke investment strategies tailored to individual risk profiles and financial goals.'
            imgPath='img/portfolio.png'
            link='/family-partners'
          />
          <Card
            title='Dedicated Funds'
            body='Offering specialized investment vehicles focused on specific asset classes or market niches.'
            imgPath='img/loan.png'
            link='/family-partners'
          />

          <Card
            title='Tailored Advisory Management'
            body='Delivering personalized financial advice and investment solutions to meet unique client needs.'
            imgPath='img/customize.png'
            link='/family-partners'
          />
          <Card
            title='Managed Certificates'
            body='Creating structured financial products designed to achieve specific investment objectives.'
            imgPath='img/certificate.png'
            link='/family-partners'
          />
        </div>
      </div>
    </>
  );
}
