import React from 'react';
import {
  TitleSection,
  FeaturesSection,
  BenefitsSection,
} from '../components/AlternativeInvestments/OpenFunds';
import LetterAnimation from '../components/LetterAnimation';
import CircularAnimation from '../components/AlternativeInvestments/CircularAnimation';
import CardsStrategies from '../components/AlternativeInvestments/CardsStrategies';
import { useState, useEffect } from 'react';

function OpenHedgeFunds() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className='w-full bg-white max-w-full overflow-x-hidden'>
      <div className='items-center text-center text-3xl pt-10 text-deepblue'>
        <h1>Open-End Hedge Funds</h1>
      </div>
      <div className='flex flex-row flex-wrap '>
        <div
          className={`${screenWidth < 1105 ? 'w-full' : 'w-1/2'} py-${
            screenWidth < 1105 ? '0' : '12'
          } text-deepblue px-16`}
        >
          <TitleSection screenWidth={screenWidth} />
        </div>
        <div
          className={`${
            screenWidth < 1105 ? 'w-full' : 'w-1/2'
          } text-deepblue items-center flex flex-col justify-center px-${
            screenWidth < 1105 ? '0' : '16'
          }`}
        >
          <FeaturesSection />
          <BenefitsSection />
        </div>
      </div>
    </div>
  );
}

function DedicatedFunds() {
  return (
    <div className='w-full'>
      <div className='z-40 text-gray-800 md:p-10 sm:p-0 bg-mist flex flex-row justify-center items-center flex-wrap'>
        <div className='m-10 sm:w-3/4 lg:w-1/3 rounded-full overflow-hidden '>
          <video
            src="gifs/city-skyscrapers-at-night-2023-11-27-05-12-41-utc.mp4"
            className='w-full h-full object-cover'
            autoPlay
            loop
            controls={false}
          ></video>
        </div>

        <div className='lg:w-1/2 p-0 sm:w-full'>
          <div className=' flex justify-center items-center text-3xl m-10'>
            <p>Dedicated Funds</p>
          </div>
          <div>
            <p className='m-10'>
              Design your own Luxembourgish Alternative Investment Fund with our
              expert assistance. We offer bespoke solutions, allowing you to
              deploy a strategy tailored to your specific investment goals.
              Managed by our proficient quantitative and research team, your
              dedicated fund will benefit from our extensive market knowledge
              and advanced analytical capabilities.
            </p>
            <p className='m-10'>
              <strong>Why Choose a Dedicated Fund?</strong>
              <ul className='p-2'>
                <li>
                  <span className='italic text-blue-500'>
                    <LetterAnimation text='Customization :   ' />
                  </span>
                  Develop a fund that aligns perfectly with your investment
                  strategy.
                </li>
                <li>
                  <span className='italic text-blue-500'>
                    <LetterAnimation text='Expert Management :   ' />
                  </span>
                  Our quantitative and research team ensures rigorous analysis
                  and strategic oversight.
                </li>
                <li>
                  <span className='italic text-blue-500'>
                    <LetterAnimation text='Luxembourg Advantage :   ' />
                  </span>
                  Benefit from the robust regulatory framework and favorable
                  investment environment of Luxembourg.
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

function AlternativeInvestment() {
  const texts = [
    'Equities',
    'Currencies',
    'Commodities',
    'Precious Metals',
    'Rates & Credits',
  ];
  const centerLabel = 'Asset Classes';
  const colors = ['bluehour', 'deepblue', 'bluehour', 'deepblue', 'bluehour'];
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <div className='items-center flex justify-center flex-wrap z-0 w-full'>
      <img
        className='fixed top-0 left-0 w-full -z-50'
        src='img/street.jpg'
        alt='street'
      />

      <div className='m-64'>
        <img
          className='top-32 left-0 w-full -z-40 filter grayscale'
          src='img/heroics-logo-rgb blanc.png'
          alt='logo'
        />
      </div>
      <OpenHedgeFunds />
      <DedicatedFunds />
      <div className='w-full '>
        <div className='z-40 text-gray-800 md:p-10 sm:p-0 bg-white p-10 flex flex-row justify-center items-center flex-wrap'>
          <div className='w-full flex justify-center items-center text-3xl m-10'>
            <p>Strategies and Asset Classes</p>
          </div>

          <div
            className={` ${
              screenWidth > 1700 ? 'w-1/2' : 'w-full'
            } py-32 flex justify-center mb-16`}
          >
            <CircularAnimation
              texts={texts}
              centerLabel={centerLabel}
              colors={colors}
            />
          </div>

          <CardsStrategies screenWidth={screenWidth} />
        </div>{' '}
      </div>
    </div>
  );
}

export default AlternativeInvestment;
