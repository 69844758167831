import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

export default function Quotes({ py }) {
  const quotes = [
    {
      title: 'A Vision',
      text: 'Discretionary management and Advisory 3.0 will revolutionize the industry, surpassing traditional companies.',
    },
    {
      title: 'A Mission',
      text: 'We drive capital growth for our investors through innovative management methods, delivering performance that is independent of market fluctuations.',
    },
    {
      title: 'An Ambition',
      text: 'Our goal is to build a win-win partners club, a unique circle of passionate entrepreneurs who inspire and support each other in achieving collective success.',
    },
  ];

  return (
    <div className={`flex justify-center flex-wrap flex-col ${py}`}>
      {quotes.map((quote, index) => (
        <QuoteItem key={index} quote={quote} index={index} />
      ))}
    </div>
  );
}

const QuoteItem = ({ quote, index }) => {
  const [ref, inView] = useInView({ threshold: 0.5 }); // Change threshold as per your requirement

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, scale: 1 }} // Initial animation state
      animate={inView ? { opacity: 1, scale: 1 } : {}} // Animation when component is in view
      transition={{ duration: 2, delay: index * 0.2 }} // Animation duration and delay
      className='w-full'
    >
      <div className='p-4 pr-10 rounded-lg'>
        <h1 className='text-4xl font-bold mb-2 italic'>"{quote.title}"</h1>
        <p className='text-sm'>{quote.text}</p>
      </div>
    </motion.div>
  );
};
