const data_family_partners = [
  {
    title: 'Advisory Services',
    text: 'Gain access to our dedicated and experienced investment advisors, who are committed to generating alpha while taking into account your unique risk appetite. Our advisory team provides personalized guidance and strategic insights to help you achieve your financial objectives.',
    elements: [
      {
        title: 'Expert Advice',
        text: 'Leverage the knowledge and experience of seasoned investment professionals.',
      },
      {
        title: 'Tailored Strategies',
        text: 'Receive customized recommendations based on your specific risk profile and investment goals.',
      },
      {
        title: 'Alpha Generation',
        text: 'Focus on strategies designed to outperform the market and achieve superior returns.',
      },
    ],
  },
  {
    title: 'Portfolio Analysis',
    text: "Benefit from Heroics Capital's expertise to consolidate your positions and aggregate all of your financial risks using smart digital tools and advanced technology. Maintaining a global overview of your entire portfolio at any time is essential for sustaining an optimal investment dynamic.",
    elements: [
      {
        title: 'Comprehensive Analysis',
        text: 'Consolidate all your financial positions for a holistic view of your portfolio.',
      },
      {
        title: 'Advanced Technology',
        text: 'Utilize cutting-edge digital tools for accurate and efficient risk aggregation.',
      },
      {
        title: 'Continuous Monitoring',
        text: 'Ensure real-time oversight of your investments to maintain the best possible investment strategy.',
      },
    ],
  },
  {
    title: 'Risk Management',
    img: './img/risks.png',
    text: 'Effectively manage and mitigate risks with our robust risk management framework. Our approach combines rigorous analysis, innovative tools, and proactive strategies to protect your investments from market volatility and unforeseen events.',
    elements: [
      {
        title: 'Proactive Strategies',
        text: 'Implement forward-thinking strategies to anticipate and mitigate potential risks.',
      },
      {
        title: 'Advanced Analytics',
        text: 'Use sophisticated tools and models to analyze and manage risk exposure.',
      },
      {
        title: 'Continuous Oversight',
        text: 'Maintain ongoing vigilance over your investments to ensure resilience against market fluctuations.',
      },
    ],
  },
  {
    title: 'Treasury Management',
    text: 'Optimize your cash management with our tailored treasury solutions. We provide strategic guidance and innovative tools to ensure efficient liquidity management, enhancing your ability to meet financial obligations and capitalize on investment opportunities.',
    elements: [
      {
        title: 'Efficient Liquidity Management',
        text: 'Ensure optimal cash flow to meet operational needs and investment opportunities.',
      },
      {
        title: 'Strategic Guidance',
        text: 'Receive expert advice on cash management strategies to enhance financial stability.',
      },
      {
        title: 'Innovative Tools',
        text: 'Utilize advanced tools for accurate forecasting and management of treasury functions.',
      },
    ],
  },
  {
    title: 'Discretionary Portfolio Management & Actively Managed Certificates',
    text: 'Entrust your portfolio to our discretionary management services and actively managed certificates. Our expert team will manage your investments on your behalf, implementing strategic decisions to optimize returns while adhering to your risk tolerance and investment objectives.',
    elements: [
      {
        title: 'Expert Management',
        text: 'Rely on our seasoned professionals to manage your portfolio with a focus on maximizing returns.',
      },
      {
        title: 'Tailored Approach',
        text: 'Benefit from a customized investment strategy aligned with your financial goals.',
      },
      {
        title: 'Dynamic Adjustments',
        text: 'Enjoy the flexibility of actively managed certificates, which adapt to market conditions to capitalize on opportunities.',
      },
    ],
  },
];

export default data_family_partners;
