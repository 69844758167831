import React, { useState, useEffect } from 'react';

export default function VideoPlayer() {
  const [videoWidth, setVideoWidth] = useState(0);
  const [videoHeight, setVideoHeight] = useState(0);

  const limit = 900; // Adjusted limit

  // Define handleResize function in a higher scope
  function handleResize() {
    const videoContainer = document.getElementById('videoContainer');
    if (videoContainer) {
      const width = videoContainer.offsetWidth;
      // Calculate height based on screen width
      const height = Math.min(0.5625 * width, limit); // Aspect ratio of 16:9 (9/16 = 0.5625)
      setVideoWidth(width);
      setVideoHeight(height);
    }
  }

  useEffect(() => {
    // Call handleResize to adapt dimensions on component load
    handleResize();

    // Event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <div className='fixed top-0 left-0 w-full'>
        <div
          id='videoContainer'
          className='w-slowme'
          style={{
            backgroundImage: `url('gifs/heroicspitch_HD.gif')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: videoHeight, // Set height dynamically
          }}
        ></div>
      </div>
      <div
        className='relative bg-transparent w-[2px] overflow-hidden'
        style={{ height: videoHeight, width: videoWidth }}
      ></div>
    </>
  );
}
