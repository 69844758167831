import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import LetterAnimation from '../../components/LetterAnimation';

export const TitleSection = ({ screenWidth }) => {
  const [refUp, inViewUp] = useInView({ threshold: 0.1 });

  // Determine text size based on screenWidth
  const textSizeClass = screenWidth < 1105 ? 'text-xl' : 'text-8xl';

  return (
    <motion.div
      className={`w-full h-full text-center italic flex items-center justify-center ${
        screenWidth > 1105 ? 'py-16 pl-10' : ''
      } text-deepblue ${textSizeClass}`}
      ref={refUp}
      initial='hidden'
      animate={inViewUp ? 'visible' : 'hidden'}
      variants={{
        hidden: { opacity: 0, x: -200 },
        visible: { opacity: 1, x: 0 },
      }}
      transition={{ duration: 0.8, ease: 'easeInOut' }}
    >
      <h1>Multi-Asset Alternative Investment Fund</h1>
    </motion.div>
  );
};

export const FeaturesSection = () => {
  const [refUp, inVewUp] = useInView({ threshold: 0.1 });

  return (
    <motion.div
      className='text-deepblue '
      ref={refUp}
      initial='hidden'
      animate={inVewUp ? 'visible' : 'hidden'}
      variants={{
        hidden: { opacity: 0, x: 200 },
        visible: { opacity: 1, x: 0 },
      }}
      transition={{ duration: 0.6, ease: 'easeInOut' }}
    >
      <ul className='list-disc pl-5 space-y-2 pt-4 m-10'>
        <li className='text-gray-700'>
          <strong className='text-blue-500'>
            <LetterAnimation text='Liquid and innovative financial instruments' />
          </strong>
          , providing strong agility in response to market conditions
        </li>
      </ul>
      <ul className='list-disc pl-5 space-y-2 pt-4 m-10'>
        <li className='text-gray-700'>
          <strong className='text-blue-500'>
            <LetterAnimation text='Multi-asset strategies' />
          </strong>
          , designed to mitigate risk through diversification, ensuring a
          balanced portfolio that can withstand various market dynamics
        </li>
      </ul>
    </motion.div>
  );
};

export const BenefitsSection = () => {
  const [refUp1, inViewUp1] = useInView({ threshold: 0.1 });
  const [refUp2, inViewUp2] = useInView({ threshold: 0.1 });

  const animationDuration1 = 0.8;
  const animationDuration2 = 0.6;

  return (
    <>
      {/* First motion div */}
      <motion.div
        ref={refUp1}
        initial='hidden'
        animate={inViewUp1 ? 'visible' : 'hidden'}
        variants={{
          hidden: { opacity: 0, x: 200 },
          visible: { opacity: 1, x: 0 },
        }}
        transition={{ duration: animationDuration1, ease: 'easeInOut' }}
        className='m-10'
      >
        <strong>Key Benefits:</strong>
        <ul className='list-disc pl-5 space-y-2 pt-3'>
          <li>
            <span className='font-ppmori italic text-blue-500'>Agility:</span>{' '}
            Use liquid financial instruments for quick adaptability.
          </li>
          <li>
            <span className='font-ppmori italic text-blue-500'>
              Diversification:
            </span>{' '}
            Deploy multi-asset strategies to spread risk.
          </li>
          <li>
            <span className='font-ppmori italic text-blue-500'>
              Innovation:
            </span>{' '}
            Access cutting-edge financial tools and techniques.
          </li>
        </ul>
      </motion.div>

      {/* Third motion div */}
      <motion.div
        ref={refUp2}
        initial='hidden'
        animate={inViewUp2 ? 'visible' : 'hidden'}
        variants={{
          hidden: { opacity: 0, x: 200 },
          visible: { opacity: 1, x: 0 },
        }}
        transition={{ duration: animationDuration2, ease: 'easeInOut' }}
        className='m-10'
      >
        <strong>Performance Extraction:</strong>
        <ul className='list-disc pl-5 space-y-2 pt-3'>
          <li>
            Our dedicated team continuously monitors and adjusts the portfolio
            to extract optimal performance, ensuring that your investments grow
            even in volatile markets.
          </li>
        </ul>
      </motion.div>
    </>
  );
};
