// import React, { useState, useEffect } from 'react';

export default function JoinUs() {
  //const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  return (
    <div className='w-full items-center flex justify-center flex-wrap z-0'>
      <img
        className='fixed top-0 left-0 w-full -z-50'
        src='img/Monaco.jpg'
        alt='clocher'
      />
      <div className='z-40 text-gray-800 bg-white p-10 w-full mt-[200px] sm:mt-[500px] flex flex-row justify-center items-center flex-wrap sm:pb-32'>
      <div className='flex justify-center items-center p-6 w-full md:w-1/3 '>
       <div className='bg-mist mt-16 p-6 flex justify-center items-center flex-col py-32 rounded-lg w-full'>
        <h1 className='text-3xl font-bold'>Contact us !</h1>
        <img
          className='w-1/3 m-10'
          src='img/heroics-logo-rgb-no-text.png'
          alt='logo'
        />
        <p>Unlock Your Potential with Heroics Capital: Join Us Today!</p>
        </div>
      </div>

        <div className=' h-min'>
          <h2 className='m-10 text-3xl font-bold mb-6 text-center'>Join Us!</h2>
          <div className='max-w-xl mx-auto'>
            <p className='mb-6'>
              At Heroics, we value innovation, collaboration, and a passion for
              finance. We believe in creating an environment where talented
              individuals can thrive and make a difference.
            </p>
            <p className='mb-6'>
              Whether you're an experienced professional or just starting your
              career, we welcome spontaneous applications from individuals who
              are enthusiastic about finance and eager to contribute to our
              dynamic startup environment.
            </p>
            <p className='mb-6'>
              To submit your resume and motivations, please send an email to{' '}
              <a
                href='mailto:hr@heroics-capital.com'
                className='text-blue-600 hover:underline'
              >
                hr@heroics-capital.com
              </a>
              . Feel free to attach any additional documents or portfolios that
              showcase your skills and experiences.
            </p>
            <p className='mb-6'>
              Additionally, you can connect with us on LinkedIn and send us a
              direct message. Visit our LinkedIn page{' '}
              <a
                href='https://www.linkedin.com/company/heroicscapital'
                className='text-blue-600 hover:underline'
              >
                here
              </a>{' '}
              to stay updated on company news and job opportunities.
            </p>
            <p className='mb-6'>
              We review all applications thoroughly and appreciate the time and
              effort you put into applying. Rest assured that every application
              is considered seriously, and we strive to provide timely feedback
              regardless of the outcome.
            </p>
            <p className='text-center'>
              We look forward to hearing from you and potentially welcoming you
              to our team!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
