import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import React, { useEffect, useState } from 'react';

function Card({
  title,
  body,
  imgPath,
  direction = 'left',
  width = '700',
  small,
}) {
  const [ref, inView] = useInView({ threshold: 0.2 });

  const cardStyle = {
    width: `${width}px`,
  };

  const initial = {
    left: { x: -100, opacity: 0 },
    right: { x: 100, opacity: 0 },
    up: { y: 100, opacity: 0 },
    down: { y: -100, opacity: 0 },
  };

  const animate = {
    left: { x: 0, opacity: 1 },
    right: { x: 0, opacity: 1 },
    up: { y: 0, opacity: 1 },
    down: { y: 0, opacity: 1 },
  };

  // Determine the icon size based on screen width
  const iconSize = small ? 80 : 250; // Adjust 16px as per your requirement
  console.log(iconSize);

  return (
    <div
      ref={ref}
      className={`flex items-center rounded-lg pr-0 bg-blue  ${
        small ? 'flex-col py-2 ' : 'py-10'
      }`}
      style={cardStyle}
    >
      <motion.img
        src={imgPath}
        alt={title}
        initial={initial[direction]}
        animate={inView ? animate[direction] : {}}
        transition={{ duration: 1.4, ease: 'easeOut' }}
        className={`w-[${iconSize}px] h-[${iconSize}px] rounded-3xl ${
          small ? 'm-1' : 'm-10'
        }`}
      />
      <motion.div
        initial={initial[direction]}
        animate={inView ? animate[direction] : {}}
        transition={{ duration: 1, delay: 0.4, ease: 'easeOut' }}
        className='flex flex-col justify-center'
      >
        <h2
          className={`font-bold mb-2 text-deepblue ${
            small ? 'text-md' : 'text-2xl'
          }`}
        >
          {title}
        </h2>
        <p className={`italic text-deepblue ${small ? 'text-sm' : 'text-xl'}`}>
          {body}
        </p>
      </motion.div>
    </div>
  );
}

export default function WhoAreWe() {
  const [small, setSmall] = useState(window.innerWidth < 1360);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setSmall(width < 1360);
    };

    // Listen to window resize events
    window.addEventListener('resize', handleResize);

    // Initial check for screen size on component mount
    setSmall(window.innerWidth < 1360);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency
  return (
    <>
      <div className='bg-white relative z-0 '>
        <div className='font-bold text-7xl p-12 justify-center flex text-deepblue'>
          Who Are We ?
        </div>
        <div className='CARDS p-10'>
          <div className='flex flex-row flex-wrap'>
            <div
              className={`flex justify-center ${small ? 'w-full' : 'w-1/2'}`}
            >
              <Card
                title='Welcome to Heroics Capital'
                body='The investment firm that redefines smart asset management in Luxembourg since 2023.'
                imgPath='img/handshake.png'
                small={small}
              />
            </div>
            <div
              className={`flex  justify-center ${
                small ? 'w-full' : 'w-1/2 pt-32 '
              }`}
            >
              <Card
                title='One Mission'
                body='We drive capital growth with innovative management, ensuring performance independent of market fluctuations.'
                imgPath='img/target.png'
                direction='right'
                small={small}
              />
            </div>
          </div>
          <div className='flex justify-center'>
            <Card
              title='Discretionary Management & Advisory Services 3.0'
              body='At Heroics, we envision the future of discretionary management & advisory services 3.0, where digital tools drive efficiency and reshape traditional paradigms.'
              imgPath='img/enlargement.png'
              direction='up'
              width='850'
              small={small}
            />
          </div>
          <div className='flex flex-row flex-wrap'>
            <div
              className={`flex  justify-center ${
                small ? 'w-full' : 'w-1/2 pt-32'
              }`}
            >
              <Card
                title='Commitment to Investors'
                body="Our commitment to investors is not just about managing assets; it's about breathing life into our strategies, passionately pursuing results and continuous improvement."
                imgPath='img/commitment.png'
                direction='left'
                small={small}

                //width={800}
              />
            </div>
            <div
              className={`flex justify-center ${
                small ? 'w-full' : 'w-1/2 mb-32'
              }`}
            >
              <Card
                title='An Exclusive Partner Club'
                body='A community of visionary entrepreneurs committed to mutual enrichment and sustainable growth.'
                imgPath='img/group.png'
                direction='right'
                small={small}
              />
            </div>
          </div>
          <div className='flex justify-center '>
            <Card
              title='Curiosity'
              body='Like our investors, we embrace curiosity, track trends, adapt to new landscapes, innovate collaboratively, and thrive on learning and evolution.'
              imgPath='img/curiosity.png'
              direction='up'
              width='800'
              small={small}
            />
          </div>
        </div>
      </div>
    </>
  );
}
