const whatsNewPosts = [
  /*
  {
    date: 'July 2024',
    title: 'Heroics trades the first Bitcoin OTC derivative with Goldman Sachs',
    content:
      'Heroics becomes the first European Hedge Fund to trade Bitcoin with Goldman Sachs Bank Europe, setting a precedent in the cryptocurrency market.',
  },
  */
  {
    date: 'July 2024',
    title: 'Launching of a new dedicated AMC',
    content:
      'July 2024 saw the introduction of a new Dedicated Actively Managed Certificate of EUR 7 million by Heroics, expanding its investment offering.',
  },
  {
    date: 'May 2024',
    title: 'Launching of a new Dedicated Fund',
    content:
      'May 2024 saw the introduction of a new Dedicated Fund by Heroics, expanding its investment offerings.',
  },
  {
    date: 'May 2024',
    title: 'Opening of the H Dynamic AMC',
    content:
      'In May 2024, Heroics announced the opening of the Dynamic Actively Managed Certificate, which aims to adapt to market changes and provide versatile investment opportunities.',
  },
  {
    date: 'June 2023',
    title: 'Launching of the Heroics Volatility Hedge Fund',
    content:
      'In June 2023, Heroics launched its highly anticipated Hedge Fund, marking a significant milestone in the European financial sector.',
  },

  // Add more posts as needed
];

export default whatsNewPosts;
